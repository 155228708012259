@import './../../styles/scss/variables';
/* Footer SCSS */
.footer {
  align-items: center;
  bottom: 0;
  display: flex;
  flex: {
    direction: row;
    wrap: nowrap;
  }
  justify-content: space-between;
  padding: {
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    top: 1rem;
  }
  position: absolute;
  width: 100vw;

  &-logo {
    font: {
      family: $fontHeader;
      size: 1.5rem;
    }
    max-width: 10rem;
  }
  &-nav {
    display: none;
    @media (min-width: $sm) {
      display: block;
      position: relative;
      right: 3rem;
    }

    li {
      padding: 0;
    }
  }
}
