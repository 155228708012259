/* Font Imports */
@font-face {
  font-family: 'NT Wagner';
  src:  url('../fonts/NT_Wagner.otf') format('opentype'),
        url('../fonts/nt_wagner-webfont.woff') format('woff'),
        url('../fonts/nt_wagner-webfont.woff2') format('woff2'),
        url('../fonts/nt_wagner-webfont.ttf') format('truetype'),
        url('../fonts/nt_wagner-webfont.svg#nt_wagner') format('svg'),
        url('../fonts/nt_wagner-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nt_wagner-webfont.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Rota';
  font-weight: 900;
  src:  url('../fonts/Rota-ExtraBlack.otf') format('opentype'),
        url('../fonts/rota-extrablack-webfont.woff') format('woff'),
        url('../fonts/rota-extrablack-webfont.woff2') format('woff2'),
        url('../fonts/rota-extrablack-webfont.ttf') format('truetype'),
        url('../fonts/rota-extrablack-webfont.svg#rota-extrablack') format('svg'),
        url('../fonts/rota-extrablack-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-extrablack-webfont.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Rota';
  font-weight: 800;
  src:  url('../fonts/Rota-ExtraBold.otf') format('opentype'),
        url('../fonts/rota-extrabold-webfont.woff') format('woff'),
        url('../fonts/rota-extrabold-webfont.woff2') format('woff2'),
        url('../fonts/rota-extrabold-webfont.ttf') format('truetype'),
        url('../fonts/rota-extrabold-webfont.svg#rota-extrabold') format('svg'),
        url('../fonts/rota-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-extrabold-webfont.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Rota';
  font-weight: 700;
  src:  url('../fonts/Rota-Black.otf') format('opentype'),
        url('../fonts/rota-black-webfont.woff') format('woff'),
        url('../fonts/rota-black-webfont.woff2') format('woff2'),
        url('../fonts/rota-black-webfont.ttf') format('truetype'),
        url('../fonts/rota-black-webfont.svg#rota-black') format('svg'),
        url('../fonts/rota-black-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-black-webfont.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Rota';
  font-weight: 600;
  src:  url('../fonts/Rota-Bold.otf') format('opentype'),
        url('../fonts/rota-bold-webfont.woff') format('woff'),
        url('../fonts/rota-bold-webfont.woff2') format('woff2'),
        url('../fonts/rota-bold-webfont.ttf') format('truetype'),
        url('../fonts/rota-bold-webfont.svg#rota-bold') format('svg'),
        url('../fonts/rota-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-bold-webfont.eot') format('embedded-opentype'); 
}
@font-face {
  font-family: 'Rota';
  font-weight: 500;
  src:  url('../fonts/Rota-Medium.otf') format('opentype'),
        url('../fonts/rota-medium-webfont.woff') format('woff'),
        url('../fonts/rota-medium-webfont.woff2') format('woff2'),
        url('../fonts/rota-medium-webfont.ttf') format('truetype'),
        url('../fonts/rota-medium-webfont.svg#rota-medium') format('svg'),
        url('../fonts/rota-medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-medium-webfont.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Rota';
  font-weight: 400;
  src:  url('../fonts/Rota-Regular.otf') format('opentype'),
        url('../fonts/rota-regular-webfont.woff') format('woff'),
        url('../fonts/rota-regular-webfont.woff2') format('woff2'),
        url('../fonts/rota-regular-webfont.ttf') format('truetype'),
        url('../fonts/rota-regular-webfont.svg#rota-regular') format('svg'),
        url('../fonts/rota-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-regular-webfont.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Rota';
  font-weight: 300;
  src:  url('../fonts/Rota-Light.otf') format('opentype'),
        url('../fonts/rota-light-webfont.woff') format('woff'),
        url('../fonts/rota-light-webfont.woff2') format('woff2'),
        url('../fonts/rota-light-webfont.ttf') format('truetype'),
        url('../fonts/rota-light-webfont.svg#rota-light-webfont') format('svg'),
        url('../fonts/rota-light-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-light-webfont.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Rota';
  font-weight: 200;
  src:  url('../fonts/Rota-ExtraLight.otf') format('opentype'),
        url('../fonts/rota-extralight-webfont.woff') format('woff'),
        url('../fonts/rota-extralight-webfont.woff2') format('woff2'),
        url('../fonts/rota-extralight-webfont.ttf') format('truetype'),
        url('../fonts/rota-extralight-webfont.svg#rota-extralight-webfont') format('svg'),
        url('../fonts/rota-extralight-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-extralight-webfont.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Rota';
  font-weight: 100;
  src:  url('../fonts/Rota-Thin.otf') format('opentype'),
        url('../fonts/rota-thin-webfont.woff') format('woff'),
        url('../fonts/rota-thin-webfont.woff2') format('woff2'),
        url('../fonts/rota-thin-webfont.ttf') format('truetype'),
        url('../fonts/rota-thin-webfont.svg#rota-thin-webfont') format('svg'),
        url('../fonts/rota-thin-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rota-thin-webfont.eot') format('embedded-opentype');
}