@import './../../styles/scss/variables';
.hero {
  &_container {
    display: block;
    height: calc(80vh + 80px);
    left: 0;
    padding: {
      left: 3rem;
      right: 2rem;
    }
    position: relative;
    top: -80px;
    width: calc(100% - 6rem);
  }

  &_background {
    display: block;
    height: calc(100vh + 80px);
    position: absolute;
    width: 100%;
  }

  &_wrapper {
    align-items: center;
    display: flex;
    flex: {
      direction: column;
      wrap: nowrap;
    }
    justify-content: center;
    height: calc(100vh + 80px);
    width: 100%;

    &.home_page {
      height: auto;
      justify-content: flex-start;
      min-height: calc(100vh + 80px);
      padding-top: 0;
      transition: min-height 1.2s ease-in-out, padding-top 1.2s ease-in-out;

      &.active {
        min-height: 0;
        padding-top: 0.75rem;
        transition: min-height 1.2s ease-in-out, padding-top 1.2s ease-in-out;
      }
    }
  }

  &_home {
    height: auto
  }

  &_altHero {
    height: 15rem;
    justify-content: flex-start;
    text-align: center;
    top: -80px;
    .hero_wrapper {
      height: 15rem;
    }
  }

  &_title {
    font: {
      size: 3rem;
    }
    line-height: 3rem;
    margin: {
      top: 0;
      bottom: 0;
    }

    @media (min-width: $sm) {
      font: {
        size: 5.5rem;
      }
      line-height: 5.5rem;
    }

    @media (min-width: $lg) {
      font: {
        size: 7.5rem;
      }
      line-height: 7.5rem;
    }
  }

  &_subtitle {
    color: $white;
    font: {
      family: $fontBody;
      size: 3rem;
      weight: 300;
    }
    line-height: 3rem;
    margin: {
      top: 0;
      bottom: 0;
    }
    min-height: 4rem;
  }

  &_svg {
    display: block;
    height: auto;
    max: {
      width: 3.5rem;
    }
    overflow: visible;
    position: relative;
    top: 0;
    transform: translateY(0%);
    transition: opacity 1.2s ease-in-out, max-width 1.2s ease-in-out, top 1.2s ease-in-out, transform 1.2s ease-in-out;
    width: 100%;

    g {
      position: relative;
    }

    &.active {
      max: {
        width: calc(100vw / 2);
      }
      
      opacity: 1;
      top: 50vh;
      transform: translateY(-50%);
      transition: opacity 1.2s ease-in-out, max-width 1.2s ease-in-out, top 1.2s ease-in-out, transform 1.2s ease-in-out;
      @media (min-width: $sm) {
        max: {
          width: calc(100vw / 3);
        }
        top: 50vh;
        transform: translateY(-50%);
        transition: opacity 1.2s ease-in-out, max-width 1.2s ease-in-out, top 1.2s ease-in-out,
        transform 1.2s ease-in-out;
      }
    }
  }
}
