/* Theme Styles */
html,
body {
  background: {
    color: $black;
  }
  color: $white;
  cursor: none;
  font: {
    family: $fontBody;
    size: 16px;
  }
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: {
    family: $fontHeader;
  }
}

h4 {
  font-size: 1.25rem;
  margin-bottom: 0;
}

h5 {
  font-size: 1rem;
}

p {
  font-szie: 1rem;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 0.5rem 0;
}

a {
  cursor: none;
}

img {
  display: block;
  height: auto;
  width: 100%;
}

main {
  min-height: 100vh;
  position: relative;
}

swiper {
  &-container {
    height: auto;
    max-height: 250px;
    max-width: 250px;
    position:relative;
    top: -80px;
    width: auto;

    @media (min-width: $sm) {
      max-height: 500px;
      max-width: 500px;
    }
  }
  &-slide {
    background-color: $white;
    box-shadow:  .5rem .5rem 0px #661c0b, 1rem 1rem 0px #381006;
    border-radius: 1rem;
    border: 0.025rem solid $black;
    height: auto;
    max-height: 500px;
    position: relative;
    width: 100%;

    &:hover {
      h2 {
        opacity: 1.0;
        text-shadow: -2.5px 2.5px 0px #661c0b, -5px 5px 0px #381006, -7.5px 7.5px 0px #0a0301, -10px 10px 0px black;
        transition: color cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, text-shadow cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, opacity 0.2s ease-in-out;
      }
    }
    

    h2, img {
      margin: auto;
      max-height: 200px;
      max-width: 200px;
      object-fit: contain;
      padding: 1.5rem;

      @media (min-width: $sm) {
        max-height: 450px;
        max-width: 450px;
      }
    }

    h2 {
      -webkit-text-stroke: 0.1rem $black;
      color: $white;
      font-size: 2rem;
      left: 50%;
      line-height: 1.5rem;
      margin: 0;
      opacity: 1;
      position: absolute;
      text-align: center;
      text-shadow: -2.5px 2.5px 0px #661c0b, -5px 5px 0px #381006, -7.5px 7.5px 0px #0a0301, -10px 10px 0px black;;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: color cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, text-shadow cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, opacity 0.2s ease-in-out;

      @media (min-width: $sm) {
        font-size: 4rem;
        line-height: 3.5rem;
        opacity: 1.0;
        text-shadow:0;
        transition: color cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, text-shadow cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, opacity 0.2s ease-in-out;
      }
    }
  }
}

.wrapper {
  max-height: 0;
  min-height: 0;
  opacity: 0;
  transition: max-height 1.2s ease-in-out, min-height 1.2s ease-in-out, opacity 1.2s ease-in-out;

  &.active {
    max-height: 100%;
    min-height: 100%;
    opacity: 1.0;
    transition: max-height 1.2s ease-in-out, min-height 1.2s ease-in-out, opacity 1.2s ease-in-out;
  }

  &-home {
    height: calc(80vh - 80px);

    &.active {
      height: auto;
    }
  }
}

.block {
  &_content {
    margin: 0 1.5rem;
  }
  &-container {
    &-item {
      &:hover {
        h4 {
          opacity: 1.0;
          text-shadow: -2.5px 2.5px 0px #661c0b, -5px 5px 0px #381006, -7.5px 7.5px 0px #0a0301, -10px 10px 0px black;
          transition: color cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, text-shadow cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, opacity 0.2s ease-in-out;
        }
      }

      h4, img {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        object-fit: contain;
      }

      h4 {
        color: $white;
        font-size: 2.5rem;
        line-height: 2rem;
        margin: 0;
        opacity: 0;
        text-shadow: 0;
        transition: color cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, text-shadow cubic-bezier(0.85, 0.15, 0.15, 0.85) 0.4s, opacity 0.2s ease-in-out;
      }
    }
  }
  &_flex {
    align-items: center;
    display: flex;
    flex: {
      direction: row;
      wrap: wrap;
    }
    gap: 1rem;
    justify-content: center;
    margin: auto;
    max-width: 300px;
    position: relative;
    top: -100px;

    @media (min-width: $sm) {
      max-width: none;
      top: 0;
    }

    &_item {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: center;
      gap: 0.5em;
      position: relative;
      width: auto;

      svg {
        color: $red;
        height: 125px;
        width: 125px;

        @media (min-width: $sm) {
          color: $white;
          transition: color .4s ease-in-out;
        }
      }

      p {
        color: $white;
        left: 50%;
        margin: 0;
        opacity: 1;
        position: absolute;
        text-shadow: -2.5px 2.5px 0px #661c0b, -5px 5px 0px #381006, -7.5px 7.5px 0px #0a0301, -10px 10px 0px black;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .4s ease-in-out;

        @media (min-width: $sm) {
          opacity: 0;
          text-shadow: 0;
        }
      }

      &:hover {
        p {
          opacity: 1;
          transition: opacity .4s ease-in-out;
        }

        svg {
            color: $red;
            transition: color .4s ease-in-out;
        }
      }
    }
  }
}
