@use './variables';
/* Buttons Scss */
.btn {
  text-decoration: none;

  &_primary {
    color: $white;
    transition: color cubic-bezier(.85,.15,.15,.85) .4s;
    &:hover {
      color: $red;
      transition: color cubic-bezier(.85,.15,.15,.85) .4s;
    }
  }

  &_bold {
    color: $white;
    font: {
      family: $fontHeader;
    }
    text-shadow: 0;
    transition: color cubic-bezier(.85,.15,.15,.85) .4s, text-shadow cubic-bezier(.85,.15,.15,.85) .4s;
    &:hover {
      color: $red;
      transition:  color cubic-bezier(.85,.15,.15,.85) .4s, text-shadow cubic-bezier(.85,.15,.15,.85) .4s;
      text-shadow: -2.5px 2.5px 0px darken($red, 10%), -5px 5px 0px darken($red, 20%), -7.5px 7.5px 0px darken($red, 30%), -10px 10px 0px darken($red, 40%);
    }
  }

  &_nav {
    color: $black;
    font: {
      family: $fontHeader;
      size: 3rem;
    }
    &:hover {
      color: $red;
      transition:  color cubic-bezier(.85,.15,.15,.85) .4s, text-shadow cubic-bezier(.85,.15,.15,.85) .4s;
      text-shadow: -5px 5px 0px darken($red, 10%), -10px 10px 0px darken($red, 20%), -15px 15px 0px darken($red, 30%), -20px 20px 0px darken($red, 40%);
    }
    @media (min-width: $sm) {
      font: {
        size: 4rem;
      }
    }
    @media (min-width: $md) {
      font: {
        size: 5rem;
      }
    }
  }

  &_hero {
    font: {
      size: 1.75rem;
    }
  }

  &_big {
    display: block;
    font: {
      size: 3.5rem;
    }
    text-align: center;
  }

  &_hero_svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
  }

}