@import './../../styles/scss/variables';
/* Cursor SCSS */
.cursor {
  @media (hover: none) {
    display: none;
  }
  pointer-events: none;
  position: fixed;
  transform: translate3d(0, 0, 0);
  z-index: 10000;
  &_main {
    background: {
      color: $red;
    }
    border: {
      radius: 50%;
    }
    height: 0.25rem;
    opacity: 1;
    transition: background-color 0.4s cubic-bezier(0.77, 0, 0.175, 1), border-width 0.4s cubic-bezier(0.77, 0, 0.175, 1),
      height 0.4s cubic-bezier(0.77, 0, 0.175, 1), width 0.4s cubic-bezier(0.77, 0, 0.175, 1),
      opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    width: 0.25rem;
  }
  &_secondary {
    background: {
      color: transparent;
    }
    border: {
      color: $white;
      radius: 50%;
      style: solid;
      width: 0.125rem;
    }
    height: 2rem;
    max: {
      height: 2rem;
      width: 2rem;
    }
    mix-blend-mode: difference;
    transition: background-color 0.4s cubic-bezier(0.77, 0, 0.175, 1), border-width 0.4s cubic-bezier(0.77, 0, 0.175, 1),
      max-height 0.4s cubic-bezier(0.77, 0, 0.175, 1), max-width 0.4s cubic-bezier(0.77, 0, 0.175, 1),
      opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    width: 2rem;
  }
  &_wrapper {
    &.link,
    &.hamburger {
      .cursor {
        &_main {
          background: {
            color: $white;
          }
          position: fixed;
          height: 0.25rem;
          width: 0.25rem;
        }
        &_secondary {
          background: {
            color: darken($red, 10%);
          }
          border: {
            color: $white;
            radius: 50%;
            style: solid;
            width: 0.125rem;
          }
          box-shadow: -2.5px 2.5px 0px darken($red, 10%), -5px 5px 0px darken($red, 20%);
          max: {
            height: 0.75rem;
            width: 0.75rem;
          }
          mix-blend-mode: normal;
          opacity: 0.2;
          transition: border-width 0.4s cubic-bezier(0.77, 0, 0.175, 1), max-height 0.4s cubic-bezier(0.77, 0, 0.175, 1),
            max-width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
        }
      }
    }
    &.link_nav {
      .cursor {
        &_main {
          background: {
            color: darken($white, 30%);
          }
          border-radius: 50%;
          height: 6rem;
          opacity: 0.15;
          transition: background-color 0.4s cubic-bezier(0.77, 0, 0.175, 1),
            border-width 0.4s cubic-bezier(0.77, 0, 0.175, 1), height 0.4s cubic-bezier(0.77, 0, 0.175, 1),
            width 0.4s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1);
          width: 6rem;
        }
        &_secondary {
          border: {
            color: $white;
            radius: 50%;
            style: solid;
            width: 0rem;
          }
          max: {
            height: 0rem;
            width: 0rem;
          }
          transition: border-width 0.4s cubic-bezier(0.77, 0, 0.175, 1), max-height 0.4s cubic-bezier(0.77, 0, 0.175, 1),
            max-width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
        }
      }
    }
  }
}
