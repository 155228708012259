// n is number of stars required
/* Creating a random number of box shadows. */
@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #F7F3E3';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #F7F3E3';
  }
  @return unquote($value);
}

$shadows-small:  multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big:    multiple-box-shadow(100);
 
.background {
  display: block;
  height: auto;
  max: {
    height: 100%;
  }
  position: relative;
  overflow: hidden;
  width: 100%;
}

.stars1 {
  animation: animateShape 50s linear infinite;
  background: transparent;
  box-shadow: $shadows-small;
  height: 1px;
  position: absolute;
  width: 1px;
  &:after {
    background: transparent;
    box-shadow: $shadows-small;
    content: " ";
    height: 1px;
    position: absolute;
    top: 2000px;
    width: 1px;
  }
}
    
.stars2 {
  animation: animateShape 100s linear infinite;
  background: transparent;
  box-shadow: $shadows-medium;
  height: 2px;
  position: absolute;
  width: 2px;
  &:after {
    background: transparent;
    box-shadow: $shadows-medium;
    content: " ";
    height: 2px;
    position: absolute;
    top: 2000px;
    width: 2px;
  }
}
    
.stars3 {
  animation: animateShape 150s linear infinite;
  background: transparent;
  box-shadow: $shadows-big;
  height: 3px;
  position: absolute;
  width: 3px;
  &:after {
    background: transparent;
    box-shadow: $shadows-big;
    content: " ";
    height: 3px;
    position: absolute;
    top: 2000px;
    width: 3px;
  }
}

@keyframes animateShape {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}