@import './../../styles/scss/variables';
/* Nav SCSS */
.nav {
  align-items: center;
  background: {
    color: $white;
  }
  color: $black;
  display: flex;
  flex: {
    direction: column;
    wrap: nowrap;
  }
  height: auto;
  justify-content: center;
  left: 0;
  max: {
    height: 0;
  }
  min: {
    height: 0;
  }
  position: fixed;
  transition: max-height cubic-bezier(.85,.15,.15,.85) .4s, min-height cubic-bezier(.85,.15,.15,.85) .4s;
  top: 0;
  width: 100%;
  ul {
    list-style-type: none;
    text-align: center;
    display: none;
    li {
      opacity: 0;
      transition: opacity cubic-bezier(.85,.15,.15,.85) .3s;
      
      @media (max-width: $sm) {
        font-size: 2rem;
      }
    }
  }
  &.active {
    max: {
      height: 100vh;
    }
    min: {
      height: 100vh;
    }
    transition: max-height cubic-bezier(.85,.15,.15,.85) .4s, min-height cubic-bezier(.85,.15,.15,.85) .4s;
    ul {
      display: block;
      li {
        opacity: 1;
        transition: opacity cubic-bezier(.85,.15,.15,.85) .4s;
      }
    }
  }
}