/* Variables SCSS */
// Colors
$black: #121616;
$white: #F7F3E3;
$red: #942910;

// Viewport Sizes
$xs: 400px;
$sm: 600px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1400px;

// Fonts
$fontBody: 'Rota', sans-serif;
$fontHeader: 'NT Wagner', serif;
