@import './../../styles/scss/variables';
/* Header SCSS */
header {
  display: flex;
  flex: {
    direction: row;
    wrap: nowrap;
  }
  height: auto;
  min: {
    height: 80px;
  }
  top: 0;
  position: relative;
  width: 100%;
  z-index: 5;

  .hamburger {
    display: block;
    height: 80px;
    position: relative;
    z-index: 6;
    .ham {
      cursor: none;
      -webkit-tap-highlight-color: transparent;
      transition: transform 0.4s;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        .line {
          filter: drop-shadow(-2.5px 2.5px 0px rgb(102, 28, 11));
          stroke: $red;
          transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s, stroke ease-in-out 0.4s, filter ease-in-out 0.4s;
        }
      }
    }
    .line {
      filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
      fill: none;
      transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s, stroke ease-in-out 0.4s, filter ease-in-out 0.4s;
      stroke: $white;
      stroke-width: 0.4rem;
      stroke-linecap: round;
    }
    .burger {
      .top {
        stroke-dasharray: 40 121;
      }
      .bottom {
        stroke-dasharray: 40 121;
      }
    }
    &.active {
      .line {
        stroke: $black;
        transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s, stroke ease-in-out 0.4s, filter ease-in-out 0.4s;
      }
      .burger {
        .top {
          stroke-dashoffset: -102px;
        }
        .bottom {
          stroke-dashoffset: -102px;
        }
      }
    }
  }
  .headroom-wrapper {
    height: 80px;
    width: 100%;
  }
}
