.animate {
  display: inline-block;
  margin: 0;
  height: auto;
  overflow: hidden;
  line-height: 3rem;
  transition: height ease-in-out .2s;
  &_in {
    height: 3rem;
    transition: height ease-in-out .2s;
  }
  &_out {
    height: 0rem;
    transition: height ease-in-out .2s;
  }
}

.text-loop {
  white-space: nowrapl
}